import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

import ContentPadding from '../components/ContentPadding'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'


const ShopPage = ({ data }) => {
  const { markdownRemark: shop } = data;
  const shop_fields = shop.frontmatter;
  const PageContent = HTMLContent || Content

  return (
    <Layout>
      <Helmet title="Voices Across Time | Shop" />
      <ContentPadding>
        <div className="section">
          <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
            {shop_fields.title}
          </h2>
          <PageContent className="content" content={shop.html} />
          
          <div className="columns is-centered is-multiline">
          {shop_fields.products.map((product, i) => {
              var optionsString = null;
              if (product.options && product.options.length) {
                optionsString = product.options.map(o => (
                  o.option + (o.price_change ? '[' + (o.price_change > 0 ? '+' : '') + o.price_change + ']' : '')
                )).join('|');
              }
              return <div className="column is-one-third" key={i}>
                <div className="card" style={{height: "100%"}} >
                  <div className="card-image">
                    <Img fluid={product.image.childImageSharp.fluid} />
                  </div>
                  <div className="card-content blog-card-content">
                    <div className="media">
                      <div className="media-content">
                        <p className="title is-size-4">
                          {product.name}</p>
                        <p className="subtitle is-size-6">£{product.price}</p>
                      </div>
                    </div>
                    <div className="content has-text-justified">
                      {product.description}
                      
                      <button className="snipcart-add-item button is-small has-background-highlight has-text-white is-pulled-right"
                        data-item-name={product.name}
                        data-item-id={product.name.replace(/\s/g, '-').replace(/[^a-zA-Z-]/g, '').toLowerCase()}
                        data-item-price={product.price}
                        data-item-url="/support-us/shop"
                        
                        data-item-custom1-name={optionsString ? (product.options_name || 'Options') : null}
                        data-item-custom1-options={optionsString}
                      >Buy Now!</button>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </ContentPadding>
    </Layout>
  )
}

export default ShopPage

export const ShopPageQuery = graphql`
  query ShopPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        templateKey
        products{
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400, quality: 75, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          description
          price
          options_name
          options {
            option
            price_change
          }
        }
      }
    }
  }
`
